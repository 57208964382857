<template>
  <v-container style="height: 100%">
    <v-row justify="center" align-content="center" style="height: 100%">
      <v-col cols="12" sm="6" md="4">
        <h2 class="font-weight-medium mb-5">Відновити пароль</h2>
        <form class="form-group">
          <PhoneEdit
            :value="phone"
            :is-touched="isPhoneTouched"
            label="Телефон"
            validate
            outlined
            dense
            @change="(val) => (phone = val)"
            @validation="(val) => (isPhoneValid = val)"
          />
          <v-text-field
            v-model="password"
            :error-messages="passwordErrors"
            label="Новий пароль"
            required
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="confirmPassword"
            :error-messages="confirmPasswordErrors"
            label="Підтвердити пароль"
            required
            @input="$v.confirmPassword.$touch()"
            @blur="$v.confirmPassword.$touch()"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            @click:append="showConfirmPassword = !showConfirmPassword"
            outlined
            dense
          ></v-text-field>

          <v-btn class="mr-4" color="success" @click="submit"> Отримати SMS код </v-btn>
          <v-btn color="error" @click="clear"> Очистити </v-btn>

          <div class="d-flex justify-space-between mt-5">
            <router-link :to="`${ROUTER_LINKS.LOGIN}`">Повернутись</router-link>
            <router-link :to="`${ROUTER_LINKS.REGISTRATION}`">Зареєструватися</router-link>
          </div>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import PhoneEdit from '@/components/common/PhoneEdit.vue'

export default {
  name: 'RestorePassword',
  components: { PhoneEdit },
  mixins: [validationMixin],

  validations: {
    password: { required },
    confirmPassword: {
      required,
      sameAsPassword: function (value) {
        return value === this.password
      },
    },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    phone: '',
    password: '',
    confirmPassword: '',
    showPassword: false,
    submitAttempted: false,
    showConfirmPassword: false,
    isPhoneValid: false,
    isPhoneTouched: false,
  }),

  computed: {
    currentPhone() {
      return this.$route.params.phone || ''
    },
    hasErr() {
      return this.$v.$anyError || !this.isPhoneValid
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Це поле обов"язкове')
      return errors
    },
    confirmPasswordErrors() {
      const errors = []
      if (!this.$v.confirmPassword.$dirty) return errors
      !this.$v.confirmPassword.required && errors.push('Це поле обов"язкове')
      if (this.submitAttempted) !this.$v.confirmPassword.sameAsPassword && errors.push('Пароль не співпадає')
      return errors
    },
  },

  mounted() {
    this.setUserPhone()
  },

  methods: {
    ...mapActions('auth', ['restorePassword']),

    setUserPhone() {
      if (this.currentPhone) this.phone = this.currentPhone
    },
    async submit() {
      this.submitAttempted = true
      this.$v.$touch()
      this.isPhoneTouched = true
      if (this.hasErr) return null
      const payload = {
        phone: this.phone,
        password: this.password,
        passwordConfirmation: this.confirmPassword,
      }
      await this.restorePassword(payload)
      await this.$router.push({ name: 'Verification', params: { phone: this.phone } })
    },
    clear() {
      this.$v.$reset()
      this.isPhoneTouched = false
      this.phone = ''
      this.password = ''
      this.confirmPassword = ''
      this.submitAttempted = false
    },
  },
}
</script>

<style scoped lang="scss"></style>
